import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PrivacyPolicyContent from "../components/PrivacyPolicyPage/PrivacyPolicyContent"
import Container from "../components/Container"

const Promo = (props) => {
  return (
    <Layout path={props.location.pathname}>
      <Seo
        title="Promo page"
        description="We evaluate the state of your car’s hybrid battery and offer solutions to regenerate or replace it. Get in touch to schedule an appointment."
        keywords="Hybrid battery, hybrid car, hybrid batteries, hybrid vehicles, hybrid battery repair, i need diagnostic for my hybrid battery, check my battery, fix my battery, battery reconditioning, hybrid battery repair, hybrid battery kitchener, car service kitchener"
      />
      <Container>
        <div style={{padding:"75px 0"}}>
          <h2>Promo page</h2>
        </div>
      </Container>
    </Layout>
  )
}

export default Promo